import { styled } from '@gluestack-style/react';
import { Switch } from 'react-native';
export default styled(Switch, {}, {
    componentName: 'Switch',
    resolveProps: [
        'thumbColor',
        'trackColor',
        'activeThumbColor',
        'ios_backgroundColor',
    ],
}, {
    propertyTokenMap: {
        trackColor: 'colors',
        thumbColor: 'colors',
        activeThumbColor: 'colors',
        ios_backgroundColor: 'colors',
    },
    propertyResolver: {
        trackColor: (rawValue, resolver) => {
            const resolveColor = {
                true: resolver(rawValue.true),
                false: resolver(rawValue.false),
            };
            return resolveColor;
        },
    },
});
